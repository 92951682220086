'use client'
import Link from "next/link";

export default function GlobalError({
                                        error,
                                        reset,
                                    }: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    console.error(error);
    return (
        <html lang="fr">
        <body>
        <div style={{
            width: '100%',
            padding: '10rem 0',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "1rem"
        }}>
            <h1>Oups, quelque chose ne va pas!</h1>
            <div>
                #{error.digest}
            </div>
            <div>
                {error.message}
            </div>
            <div style={{display:"flex", flexDirection:"row", gap:"2rem"}}>
                <Link href={`/`} className={"button red"}>Retourner en page d'accueil</Link>
                <button onClick={() => reset()}>Try again</button>
            </div>
        </div>
        </body>
        </html>
    );
}